import React from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import PopoverComponent from '../popover';
import { Card } from 'react-bootstrap';

const Yearly_mileage = ({ value }) => {
  let graphData1 = "";

  try {
    graphData1 = JSON.parse(value);
  } catch (error) {
    console.error("Error parsing JSON", error);
  }

  return (
<Card className='card_ride' >
    <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
     Yearly Mileage
      </ManText>
      <PopoverComponent id= 'Yearly_mil' content='This KPI displays the yearly driven distance of the vehicle.

      If more than one vehicle is selected, both the sum and the average value of yearly mileage are displayed.

      For the current year a linear extrapolation of the previous data provides an estimate for the annual mileage at the end of the year.' />

      </div>
    
      <Plot 
        data={graphData1.data} 
        layout={{
            ...graphData1.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '85%' }}
        useResizeHandler={true}
    />
    </Card>
  );
};

Yearly_mileage.propTypes = {
  value: PropTypes.string.isRequired,
};

export default Yearly_mileage;