import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import { Card } from 'react-bootstrap';

const formatNumber = (number) => {
  if (isNaN(number)) return ''; // Return as is if not a number
  return number.toLocaleString('de-DE');      // 'de-DE' for German locale, 'en-IN' for Indian English locale
};
const All_time_energythroughput = ({ value }) => {
  const [all_time_energy_throughput, set_all_time_energy_throughput] = useState(null);

  console.log('packs',value)








  useEffect(() => {
    try {
        set_all_time_energy_throughput(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
<Card className='card_battery' >      
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
        All Time Energy Throughput
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI displays the total energy throughput (all time) of the vehicle.

If more than one vehicle is selected average values are displayed.' />


      <div>
        {all_time_energy_throughput && all_time_energy_throughput.data ? (
          <Plot 
          data={all_time_energy_throughput.data} 
          layout={{
              ...all_time_energy_throughput.layout,
              autosize: true,
              responsive: true,
              margin: { t: 10, r: 10, l: 10, b: 10 }
          }}
          style={{ width: '100%', height: '100%' }}
          useResizeHandler={true}
      />
      
        ) : (
          <div>
        <ManText variant={"heading-s"} as={"span"}>{value} kWh</ManText>
        </div>
      )}
    </div>
      </Card>
  );
};

All_time_energythroughput.propTypes = {
  value: PropTypes.string.isRequired,
};

export default All_time_energythroughput;