import Header from '../components/Header';
import Sidebar from '../components/Sidebar';
import Graphs from '../components/Graphs';
import MAN_info from '../components/MAN_info';
import Footer from '../components/Footer';
import NewFeaturesModal from './New_feature';
const Mainscreen = () => {

return (
    <div className='app'>
      <NewFeaturesModal />
      <Header />
      <MAN_info />
    <div className='main-content'>
      <Sidebar />
      {/* <Graphs /> */}
    </div>
      
    </div>

);
};

export default Mainscreen;