import { 
    BarChart, 
    TotalMileageCard, 
    AverageSpeed, 
    TotalDrivingTimeHrs, 
    DailyMileage, 
    Average_consumption_speed_class, 
    Yearly_mileage, 
    Start_of_data_acquisition, 
    Total_Standing_time 
  } from './ride_analysis_kpi';
  import { ManText } from "@craft-design-system/core-components-react";
  import { useGraphContext } from '../GraphContext';
  import { Card } from 'react-bootstrap';

const Ride_Analysis=()=>{
    const { graphData } = useGraphContext();


    const {
        total_mil,
        start_of_data_acquisition,
        median_consum,
        vin_short,
        daily_mileage,
        average_consumption_per_speed_class_standing,
        yearly_mileage,
        average_speed,
        total_driving_time,
        total_standing_time,
      } = graphData;

    return (
        <div> 
            {/* <ManText className="graph-text" variant={"heading-l"} as={"span"}>
Ride Analysis
</ManText> */}
{/* <div className="card-info">

</div> */}
<div className="card-info1">
    <TotalMileageCard value={total_mil}/>
    <Start_of_data_acquisition value={start_of_data_acquisition}/>
    <BarChart value={{ median_consum, vin_short }}/>
    <DailyMileage value={{ daily_mileage, vin_short }}/>
    <Yearly_mileage value={yearly_mileage}/>
    <AverageSpeed value={{ average_speed, vin_short }}/>
    <TotalDrivingTimeHrs value={{ total_driving_time, vin_short }}/>
    <Total_Standing_time value={{ total_standing_time, vin_short }}/>
    <Average_consumption_speed_class value={{ standstill_consumption: average_consumption_per_speed_class_standing, vin_short }}/>






  {/* <Card className='firstcard'>
  </Card>
  <Card className='firstcard'>
  </Card>
  <Card className='firstcard'>
  </Card>
  <Card className='firstcard'>
  </Card>
  <Card className='firstcard'>
  </Card>
  <Card className='firstcard'>
  </Card> */}
</div>

</div>
    )

}

export default Ride_Analysis
