import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import Plot from 'react-plotly.js';
import { ManRadioButtonGroup, ManRadioButton,ManText } from '@craft-design-system/core-components-react';
import useGraphRadio from '../../utils/useGraphRadio';
import Shimmer_graph from '../shimmer_graph';
import ifav from './ifav.png';
import PopoverComponent from '../popover';
import { Card } from 'react-bootstrap';

const TotalDrivingTimeHrs= ({ value }) => {
  const [total_driving_time, set_total_driving_time] = useState(null);
  const radio_query = useGraphRadio();
  const [radio_buttons_test, set_Radio_values] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleSelectChange1 = (event) => {
    set_Radio_values((prevValues) => ({
      ...prevValues,
      [event.detail.name]: event.detail.value,
    }));
  };

  useEffect(() => {
    if (radio_buttons_test) {
      setIsLoading(true);

      //console.log(value.vin_short)
      radio_query({vins_short:[value.vin_short],radio_value:radio_buttons_test,columnname:'Total Driving Time'}).then(data => {
        set_total_driving_time(JSON.parse(data['jsonData']));
        setIsLoading(false);

      });
    }
  }, [radio_buttons_test]);

  useEffect(() => {
    //console.log('testing23456', total_driving_time);
  }, [total_driving_time]);

  useEffect(() => {
    try {
      set_total_driving_time(JSON.parse(value.total_driving_time));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <Card className='card_ride' >
    <div className="card-title">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Daily Total Driving Time
      </ManText>
      <PopoverComponent id= 'driving_time' content='This KPI displays the total driving time of the vehicle  within one calendar day.

If more than one vehicle is selected average values are displayed' />

      <ManRadioButtonGroup 
        name="Total Driving Time"
        invalid="false"
        onManChange={handleSelectChange1}
      >
        <ManRadioButton name="Daily Total Driving Time" label="Daily" value="Daily" checked />
        <ManRadioButton name="Daily Total Driving Time" label="Monthly" value="Monthly" />
        <ManRadioButton name="Daily Total Driving Time" label="Yearly" value="Yearly" />
      </ManRadioButtonGroup>
      </div>
      {isLoading ? <Shimmer_graph /> : total_driving_time && <Plot 
        data={total_driving_time.data} 
        layout={{
            ...total_driving_time.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '90%' }}
        useResizeHandler={true}
    />}    
      </Card>
  );
};

TotalDrivingTimeHrs.propTypes = {
  value: PropTypes.string.isRequired,
};
 
export default TotalDrivingTimeHrs;