import PropTypes from 'prop-types';
import { ManCard, ManText } from '@craft-design-system/core-components-react';
import PopoverComponent from '../popover';
import { Button, Popover, OverlayTrigger } from 'react-bootstrap';
import React, { useState, useEffect } from 'react';
import Plot from 'react-plotly.js';
// import { ManRadioButtonGroup, ManRadioButton,ManText,ManCard } from '@craft-design-system/core-components-react';
import ifav from './ifav.png';
import { Card } from 'react-bootstrap';

const Start_of_data_acquisition = ({ value }) => {
  const [start_of_data_acquisition, set_start_of_data_acquisition] = useState(null);

  console.log('packs',value)

 





  useEffect(() => {
    try {
        set_start_of_data_acquisition(JSON.parse(value));
    } catch (error) {
      console.error("Error parsing JSON", error);
    }
  }, [value]);

  return (
    <Card className='card_ride' appearance="soft" outline="false">
      <ManText className="card-title-text" variant="component-l" as="span" size="l" 
       style={{}}>
      Start of data acquisition      
      </ManText>
      <PopoverComponent id= 'median_consumption' content='This KPI shows the earliest time stamp at which telematics data is available for the selected vehicle. This time stamp is the baseline for different KPIs on the dashboard (e.g. Total Driven Distance, State of Health).

No value is displayed if more than one vehicle is selected.' />
      


      <div >
      {start_of_data_acquisition && start_of_data_acquisition.data ? (
        <Plot 
        data={start_of_data_acquisition.data} 
        layout={{
            ...start_of_data_acquisition.layout,
            autosize: true,
            responsive: true,
            margin: { t: 10, r: 10, l: 10, b: 10 }
        }}
        style={{ width: '100%', height: '100%' }}
        useResizeHandler={true}
    />
      ) : (
        <div>
        <ManText className="card-title-text" variant="component-l" as="span" size="l" >{value}</ManText>
        </div>
      )}
    </div>
      </Card>
  );
};

Start_of_data_acquisition.propTypes = {
  value: PropTypes.number.isRequired,
};

export default Start_of_data_acquisition;